/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
class s {
  constructor(s, e = 30) {
    this.name = s, this._counter = 0, this._samples = new Array(e);
  }
  record(s) {
    null != s && (this._samples[++this._counter % this._samples.length] = s);
  }
  get median() {
    return this._samples.slice().sort((s, e) => s - e)[Math.floor(this._samples.length / 2)];
  }
  get average() {
    return this._samples.reduce((s, e) => s + e, 0) / this._samples.length;
  }
  get last() {
    return this._samples[this._counter % this._samples.length];
  }
}
export { s as default };