/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import t from "../../Logger.js";
import { ensureArrayTyped as e, ensureOneOfType as r, isLongFormType as n, ensureLongFormType as o, isOneOf as s, ensureOneOf as i, ensureNArrayTyped as a, ensureArray as p, ensureNArray as u, ensureType as c } from "../ensureType.js";
import { get as f } from "../get.js";
import { getPropertyMetadata as l, mergeProperty as g } from "../metadata.js";
import { set as m } from "../set.js";
function y(n = {}) {
  return (o, s) => {
    if (o === Function.prototype) throw new Error(`Inappropriate use of @property() on a static field: ${o.name}.${s}. Accessor does not support static properties.`);
    const i = Object.getOwnPropertyDescriptor(o, s),
      a = l(o, s);
    i && (i.get || i.set ? (a.get = i.get || a.get, a.set = i.set || a.set) : "value" in i && ("value" in n && t.getLogger("esri.core.accessorSupport.decorators.property").warn(`@property() will redefine the value of "${s}" on "${o.constructor.name}" already defined in the metadata`, n), a.value = n.value = i.value)), null != n.readOnly && (a.readOnly = n.readOnly);
    const p = n.aliasOf;
    if (p) {
      const t = "string" == typeof p ? p : p.source,
        e = "string" == typeof p ? null : !0 === p.overridable;
      let r;
      a.dependsOn = [t], a.get = function () {
        let e = f(this, t);
        if ("function" == typeof e) {
          r || (r = t.split(".").slice(0, -1).join("."));
          const n = f(this, r);
          n && (e = e.bind(n));
        }
        return e;
      }, a.readOnly || (a.set = e ? function (t) {
        this._override(s, t);
      } : function (e) {
        m(this, t, e);
      });
    }
    const u = n.type,
      c = n.types;
    a.cast || (u ? a.cast = h(u) : c && (Array.isArray(c) ? a.cast = e(r(c[0])) : a.cast = r(c))), g(a, n), n.range && (a.cast = j(a.cast, n.range));
  };
}
function d(t, e, r) {
  const n = l(t, r);
  n.json || (n.json = {});
  let o = n.json;
  return void 0 !== e && (o.origins || (o.origins = {}), o.origins[e] || (o.origins[e] = {}), o = o.origins[e]), o;
}
function h(t) {
  let e = 0,
    r = t;
  if (n(t)) return o(t);
  for (; Array.isArray(r) && 1 === r.length && "string" != typeof r[0] && "number" != typeof r[0];) r = r[0], e++;
  const f = r;
  if (s(f)) return 0 === e ? i(f) : a(i(f), e);
  if (1 === e) return p(f);
  if (e > 1) return u(f, e);
  const l = t;
  return l.from ? l.from : c(l);
}
function j(t, e) {
  return r => {
    let n = +t(r);
    return null != e.step && (n = Math.round(n / e.step) * e.step), null != e.min && (n = Math.max(e.min, n)), null != e.max && (n = Math.min(e.max, n)), n;
  };
}
export { j as ensureRange, y as property, d as propertyJSONMeta };