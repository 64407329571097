/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
let r;
function o() {
  return r;
}
function n(o) {
  r = o;
}
var t;
!function (r) {
  r[r.Ignore = 0] = "Ignore", r[r.Destroy = 1] = "Destroy", r[r.ThrowError = 2] = "ThrowError";
}(t || (t = {}));
export { t as InterceptorCollectAction, o as getAccessorInterceptor, n as setAccessorInterceptor };