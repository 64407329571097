/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { isIterable as t } from "./iteratorUtils.js";
class r {
  constructor() {
    this._groups = new Map();
  }
  destroy() {
    this.removeAll();
  }
  get size() {
    let t = 0;
    return this._groups.forEach(r => {
      t += r.length;
    }), t;
  }
  add(r, e) {
    if (t(r)) {
      const t = this._getOrCreateGroup(e);
      for (const e of r) o(e) && t.push(e);
    } else if (o(r)) {
      this._getOrCreateGroup(e).push(r);
    }
    return this;
  }
  forEach(t, r) {
    if ("function" == typeof t) this._groups.forEach(r => r.forEach(t));else {
      const e = this._getGroup(t);
      e && r && e.forEach(r);
    }
  }
  has(t) {
    return this._groups.has(e(t));
  }
  remove(r) {
    if ("string" != typeof r && t(r)) {
      for (const t of r) this.remove(t);
      return this;
    }
    return this.has(r) ? (s(this._getGroup(r)), this._groups.delete(e(r)), this) : this;
  }
  removeAll() {
    return this._groups.forEach(s), this._groups.clear(), this;
  }
  removeReference(t) {
    return this._groups.delete(t), this;
  }
  _getOrCreateGroup(t) {
    if (this.has(t)) return this._getGroup(t);
    const r = [];
    return this._groups.set(e(t), r), r;
  }
  _getGroup(t) {
    return this._groups.get(e(t));
  }
}
function e(t) {
  return t || "_default_";
}
function s(t) {
  for (const e of t) e instanceof r ? e.removeAll() : e.remove();
}
function o(t) {
  return null != t && (!!t.remove || t instanceof r);
}
export { r as default };