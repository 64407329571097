/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { equals as r } from "../arrayUtils.js";
import e from "../Error.js";
import t from "../Logger.js";
import { get as i } from "./get.js";
import { idToReadableName as o, OriginId as n, nameToId as s } from "./PropertyOrigin.js";
import { getProperties as u, merge as l } from "./utils.js";
import { originSpecificWritePropertyDefinition as a } from "./extensions/serializableProperty.js";
function p(r, e, t, i, o) {
  const n = {};
  return e.write?.writer?.call(r, i, n, t, o), n;
}
function f(r, o, u, l, a, p) {
  if (!l?.write) return !1;
  const f = i(r, u);
  if (!a && l.write.overridePolicy) {
    const e = l.write.overridePolicy.call(r, f, u, p ?? void 0);
    void 0 !== e && (a = e);
  }
  if (a || (a = l.write), !a || !1 === a.enabled) return !1;
  if (a.layerContainerTypes && p?.layerContainerType && !a.layerContainerTypes.includes(p.layerContainerType)) return !1;
  if ((null === f && !a.allowNull && !a.writerEnsuresNonNull || void 0 === f) && a.isRequired) {
    const i = new e("web-document-write:property-required", `Missing value for required property '${u}' on '${r.declaredClass}'`, {
      propertyName: u,
      target: r
    });
    return i && p?.messages ? p.messages.push(i) : i && !p && t.getLogger("esri.core.accessorSupport.write").error(i.name, i.message), !1;
  }
  if (void 0 === f) return !1;
  if (null === f && !a.allowNull && !a.writerEnsuresNonNull) return !1;
  if (!a.alwaysWriteDefaults && (!o.store.multipleOriginsSupported || o.store.originOf(u) === n.DEFAULTS) && c(r, u, p, l, f)) return !1;
  if (!a.ignoreOrigin && p?.origin && o.store.multipleOriginsSupported) {
    if (o.store.originOf(u) < s(p.origin)) return !1;
  }
  return !0;
}
function c(e, t, i, o, n) {
  const s = o.default;
  if (void 0 === s) return !1;
  if (null != o.defaultEquals) return o.defaultEquals(n);
  if ("function" == typeof s) {
    if (Array.isArray(n)) {
      const o = s.call(e, t, i ?? void 0);
      return r(o, n);
    }
    return !1;
  }
  return s === n;
}
function g(r, e, t, i) {
  const o = u(r),
    n = o.metadata,
    s = a(n[e], i);
  return !!s && f(r, o, e, s, t, i);
}
function d(r, e, t) {
  if (r && "function" == typeof r.toJSON && (!r.toJSON.isDefaultToJSON || !r.write)) return l(e, r.toJSON(t));
  const n = u(r),
    s = n.metadata;
  for (const u in s) {
    const c = a(s[u], t);
    if (!f(r, n, u, c, void 0, t)) continue;
    const g = i(r, u),
      d = p(r, c, c.write && "string" == typeof c.write.target ? c.write.target : u, g, t);
    Object.keys(d).length > 0 && (e = l(e, d), t?.resources?.pendingOperations?.length && t.resources.pendingOperations.push(Promise.all(t.resources.pendingOperations).then(() => l(e, d, () => "replace-arrays"))), t?.writtenProperties && t.writtenProperties.push({
      target: r,
      propName: u,
      oldOrigin: o(n.store.originOf(u)),
      newOrigin: t.origin
    }));
  }
  return e;
}
export { g as willPropertyWrite, d as write };