/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { deprecatedFunction as e } from "./deprecate.js";
import r from "./Handles.js";
import t from "./Logger.js";
import { destroyMaybe as s } from "./maybe.js";
import { beforeDestroySymbol as o } from "./accessorSupport/beforeDestroy.js";
import { get as i } from "./accessorSupport/get.js";
import { Lifecycle as c } from "./accessorSupport/interfaces.js";
import { getPropertiesMetadata as n } from "./accessorSupport/metadata.js";
import a from "./accessorSupport/Properties.js";
import { set as p } from "./accessorSupport/set.js";
import { getAccessorInterceptor as _ } from "./accessorSupport/testSupport.js";
import { removeTarget as h, watch as l } from "./accessorSupport/watch.js";
import { property as u } from "./accessorSupport/decorators/property.js";
import { subclass as d } from "./accessorSupport/decorators/subclass.js";
var f, m;
function y(e) {
  if (null == e) return {
    value: e
  };
  if (Array.isArray(e)) return {
    type: [e[0]],
    value: null
  };
  switch (typeof e) {
    case "object":
      return e.constructor?.__accessorMetadata__ || e instanceof Date ? {
        type: e.constructor,
        value: e
      } : e;
    case "boolean":
      return {
        type: Boolean,
        value: e
      };
    case "string":
      return {
        type: String,
        value: e
      };
    case "number":
      return {
        type: Number,
        value: e
      };
    case "function":
      return {
        type: e,
        value: null
      };
    default:
      return;
  }
}
const v = Symbol("Accessor-Handles"),
  g = Symbol("Accessor-Initialized");
class S {
  static createSubclass(e = {}) {
    if (Array.isArray(e)) throw new Error("Multi-inheritance unsupported since 4.16");
    const {
      properties: r,
      declaredClass: t,
      constructor: s
    } = e;
    delete e.declaredClass, delete e.properties, delete e.constructor;
    const o = this;
    class i extends o {
      constructor(...e) {
        super(...e), this.inherited = null, s && s.apply(this, e);
      }
    }
    n(i.prototype);
    for (const c in e) {
      const r = e[c];
      i.prototype[c] = "function" == typeof r ? function (...e) {
        const t = this.inherited;
        let s;
        this.inherited = function (...e) {
          if (o.prototype[c]) return o.prototype[c].apply(this, e);
        };
        try {
          s = r.apply(this, e);
        } catch (i) {
          throw this.inherited = t, i;
        }
        return this.inherited = t, s;
      } : e[c];
    }
    for (const c in r) {
      const e = y(r[c]);
      u(e)(i.prototype, c);
    }
    return d(t)(i);
  }
  constructor(...e) {
    if (this[f] = null, this[m] = !1, this.constructor === S) throw new Error("[accessor] cannot instantiate Accessor. This can be fixed by creating a subclass of Accessor");
    const r = new a(this);
    Object.defineProperty(this, "__accessor__", {
      enumerable: !1,
      value: r
    }), e.length > 0 && (r.ctorArgs = this.normalizeCtorArgs?.apply(this, e) ?? e[0]), _()?.onInstanceConstruct(this);
  }
  postscript() {
    const e = this.__accessor__,
      r = e.ctorArgs;
    e.initialize(), r && (this.set(r), e.ctorArgs = null), e.constructed(), this.initialize(), this[g] = !0;
  }
  initialize() {}
  [o]() {
    this[v] = s(this[v]);
  }
  destroy() {
    this.destroyed || (h(this), this.__accessor__.destroy(), _()?.onInstanceDestroy(this));
  }
  [Symbol.dispose]() {
    this.destroy();
  }
  get constructed() {
    return this.__accessor__ && this.__accessor__.initialized || !1;
  }
  get initialized() {
    return this[g];
  }
  get destroyed() {
    return this.__accessor__?.lifecycle === c.DESTROYED || !1;
  }
  commitProperty(e) {
    i(this, e);
  }
  get(r) {
    return e(t.getLogger(this), "`Accessor.get` is deprecated in favor of using optional chaining", {
      version: "4.28",
      see: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining"
    }), i(this, r);
  }
  hasOwnProperty(e) {
    return this.__accessor__ ? this.__accessor__.has(e) : Object.prototype.hasOwnProperty.call(this, e);
  }
  keys() {
    return this.__accessor__ ? this.__accessor__.keys() : [];
  }
  set(e, r) {
    return p(this, e, r), this;
  }
  watch(e, r, t) {
    return l(this, e, r, t);
  }
  addHandles(e, t) {
    if (this.destroyed) {
      const r = Array.isArray(e) ? e : [e];
      for (const e of r) e.remove();
      return;
    }
    (this[v] ??= new r()).add(e, t);
  }
  removeHandles(e) {
    this[v]?.remove(e);
  }
  removeAllHandles() {
    this[v]?.removeAll();
  }
  removeHandlesReference(e) {
    this[v]?.removeReference(e);
  }
  hasHandles(e) {
    return !0 === this[v]?.has(e);
  }
  _override(e, r) {
    void 0 === r ? this.__accessor__.clearOverride(e) : this.__accessor__.override(e, r);
  }
  _clearOverride(e) {
    return this.__accessor__.clearOverride(e);
  }
  _overrideIfSome(e, r) {
    null == r ? this.__accessor__.clearOverride(e) : this.__accessor__.override(e, r);
  }
  _isOverridden(e) {
    return this.__accessor__.isOverridden(e);
  }
  notifyChange(e) {
    this.__accessor__.notifyChange(e);
  }
  _get(e) {
    return this.__accessor__.internalGet(e);
  }
  _set(e, r) {
    return this.__accessor__.internalSet(e, r), this;
  }
}
f = v, m = g;
export { S as default };