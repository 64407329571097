/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function n(n, r) {
  for (const t of n.entries()) if (r(t[0])) return !0;
  return !1;
}
function r(n, r) {
  if (!r) return n;
  for (const t of r) null != t && n.add(t);
  return n;
}
function t(n, r) {
  return null != r && n.add(r), n;
}
function o(n, t) {
  const o = new Set();
  return r(o, n), r(o, t), o;
}
function u(n, r) {
  const t = new Set();
  for (const o of r) n.has(o) && t.add(o);
  return t;
}
function e(n, r) {
  if (!n || !r) return !1;
  if (n === r) return !0;
  for (const t of n) if (!r.has(t)) return !1;
  return !0;
}
function f(n, r) {
  if (null == n && null == r) return !0;
  if (null == n || null == r || n.size !== r.size) return !1;
  for (const t of n) if (!r.has(t)) return !1;
  return !0;
}
function i(n, r) {
  const t = new Set(n);
  for (const o of r) t.delete(o);
  return t;
}
function c(n, r) {
  return i(o(n, r), u(n, r));
}
function s(n) {
  let r;
  for (r of n);
  return r;
}
export { r as addMany, t as addMaybe, i as difference, f as equals, u as intersection, e as isSubsetOf, s as last, n as someSet, c as symmetricDifference, o as union };