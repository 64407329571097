/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { ObservableBase as t } from "./ObservableBase.js";
import { OriginId as s } from "./PropertyOrigin.js";
import { trackAccess as e, runTracked as i, trackExplicitDependencies as a, runUntracked as n } from "./tracking.js";
import { Flags as o } from "./tracking/Flags.js";
class r extends t {
  constructor(t, s) {
    super(), this.propertyName = t, this.metadata = s, this._accessed = null, this._handles = null, this.flags = 0, this.flags = o.Dirty | (s.nonNullable ? o.NonNullable : 0) | (s.hasOwnProperty("value") ? o.HasDefaultValue : 0) | (void 0 === s.get ? o.DepTrackingInitialized : 0) | (void 0 === s.dependsOn ? o.AutoTracked : 0);
  }
  destroy() {
    this.flags & o.Dirty && this.onCommitted(), super.destroy(), this._accessed = null, this._clearObservationHandles();
  }
  getComputed(t) {
    e(this);
    const r = t.store,
      l = this.propertyName,
      h = this.flags,
      d = r.get(l);
    if (h & o.Computing) return d;
    if (~h & o.Dirty && r.has(l)) return d;
    this.flags |= o.Computing;
    const c = t.host;
    let g;
    h & o.AutoTracked ? g = i(this, this.metadata.get, c) : (a(c, this), g = this.metadata.get.call(c)), r.set(l, g, s.COMPUTED);
    const m = r.get(l);
    return m === d ? this.flags &= ~o.Dirty : n(this.commit, this), this.flags &= ~o.Computing, m;
  }
  onObservableAccessed(t) {
    t !== this && (this._accessed ??= new Set(), this._accessed.add(t));
  }
  onTrackingEnd() {
    this._clearObservationHandles(), this.flags |= o.DepTrackingInitialized, null != this._accessed && (this._handles ??= [], this._accessed.forEach(t => {
      this._handles.push(t.observe(this));
    }), this._accessed.clear());
  }
  notifyChange() {
    this.onInvalidated(), this.onCommitted();
  }
  invalidate() {
    this.onInvalidated();
  }
  commit() {
    this.flags &= ~o.Dirty, this.onCommitted();
  }
  _clearObservationHandles() {
    const t = this._handles;
    if (null !== t) {
      for (let s = 0; s < t.length; ++s) t[s].remove();
      t.length = 0;
    }
  }
  onInvalidated() {
    ~this.flags & o.Overridden && (this.flags |= o.Dirty);
    const t = this._observers;
    if (t && t.length > 0) for (const s of t) s.onInvalidated();
  }
  onCommitted() {
    const t = this._observers;
    if (t && t.length > 0) {
      const s = t.slice();
      for (const t of s) t.onCommitted();
    }
  }
}
export { r as Property };