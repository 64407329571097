/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import has from "./has.js";
const e = new WeakMap();
function n(n, r, t = !1) {
  const i = `🛑 DEPRECATED - ${r}`;
  if (!t) return void n.warn(i);
  let o = e.get(n);
  o || (o = new Set(), e.set(n, o)), o.has(r) || (o.add(r), n.warn(i));
}
function r(e, n, r = {}) {
  has("esri-deprecation-warnings") && a(e, `Module: ${n}`, r);
}
function t(e, n, r = {}) {
  if (has("esri-deprecation-warnings")) {
    const {
      moduleName: t
    } = r;
    a(e, `Function: ${(t ? t + "::" : "") + n + "()"}`, r);
  }
}
function i(e, n, r = {}) {
  if (has("esri-deprecation-warnings")) {
    const {
      moduleName: t
    } = r;
    a(e, `Property: ${(t ? t + "::" : "") + n}`, r);
  }
}
function o(e, n, r = {}) {
  has("esri-deprecation-warnings") && a(e, `Multiple argument constructor: ${n}`, {
    warnOnce: !0,
    replacement: `new ${n}({ <your properties here> })`,
    ...r
  });
}
function a(e, r, t = {}) {
  if (has("esri-deprecation-warnings")) {
    const {
      replacement: i,
      version: o,
      see: a,
      warnOnce: s
    } = t;
    let c = r;
    i && (c += `\n\t🛠️ Replacement: ${i}`), o && (c += `\n\t⚙️ Version: ${o}`), a && (c += `\n\t🔗 See ${a} for more details.`), n(e, c, s);
  }
}
export { a as deprecated, o as deprecatedConstructorSignature, t as deprecatedFunction, r as deprecatedModule, i as deprecatedProperty };