/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import t from "./Accessor.js";
import { DefaultsStore as o } from "./accessorSupport/DefaultsStore.js";
import { setupConstructedDefaults as s } from "./accessorSupport/defaultsStoreUtils.js";
import { read as e } from "./accessorSupport/read.js";
import { getProperties as c } from "./accessorSupport/utils.js";
import { write as i } from "./accessorSupport/write.js";
import { subclass as p } from "./accessorSupport/decorators/subclass.js";
const u = t => {
  let u = class extends t {
    constructor(...r) {
      super(...r);
      const t = c(this),
        e = t.store,
        i = new o();
      t.store = i, s(t, e, i);
    }
    read(r, t) {
      e(this, r, t);
    }
    write(r, t) {
      return i(this, r ?? {}, t);
    }
    toJSON(r) {
      return this.write({}, r);
    }
    static fromJSON(r, t) {
      return n.call(this, r, t);
    }
  };
  return u = r([p("esri.core.JSONSupport")], u), u.prototype.toJSON.isDefaultToJSON = !0, u;
};
function n(r, t) {
  if (!r) return null;
  if (r.declaredClass) throw new Error("JSON object is already hydrated");
  const o = new this();
  return o.read(r, t), o;
}
function a(r) {
  return r && "object" == typeof r && "toJSON" in r && "function" == typeof r.toJSON;
}
let f = class extends u(t) {};
f = r([p("esri.core.JSONSupport")], f);
export { f as JSONSupport, u as JSONSupportMixin, a as isSerializable };