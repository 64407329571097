/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "../has.js";
import { equals as t } from "../lang.js";
import "../Logger.js";
import e from "../ObjectPool.js";
import { Lifecycle as i } from "./interfaces.js";
import { Property as s } from "./Property.js";
import { OriginId as r, idToName as o, nameToId as a } from "./PropertyOrigin.js";
import { Store as n } from "./Store.js";
import { trackAccess as c, runTracked as l, initializeDependencyTracking as h } from "./tracking.js";
import { Flags as p } from "./tracking/Flags.js";
function f(t, e, i) {
  return void 0 !== t;
}
function d(t, e, s, r) {
  return void 0 !== t && (!(null == s && t.flags & p.NonNullable) || (r.lifecycle, i.INITIALIZING, !1));
}
class g {
  constructor(t) {
    this.host = t, this.propertiesByName = new Map(), this.ctorArgs = null, this.lifecycle = i.INITIALIZING, this.store = new n(), this._origin = r.USER;
    const e = this.host.constructor.__accessorMetadata__;
    for (const i in e) {
      const t = new s(i, e[i]);
      this.propertiesByName.set(i, t);
    }
    this.metadata = e;
  }
  initialize() {
    this.lifecycle = i.CONSTRUCTING;
  }
  constructed() {
    this.lifecycle = i.CONSTRUCTED;
  }
  destroy() {
    this.lifecycle = i.DESTROYED, this.propertiesByName.forEach(t => t.destroy());
  }
  get initialized() {
    return this.lifecycle !== i.INITIALIZING;
  }
  get(t) {
    const e = this.propertiesByName.get(t);
    if (e.metadata.get) return e.getComputed(this);
    c(e);
    const i = this.store;
    return i.has(t) ? i.get(t) : e.metadata.value;
  }
  originOf(t) {
    const e = this.store.originOf(t);
    if (void 0 === e) {
      const e = this.propertiesByName.get(t);
      if (void 0 !== e && e.flags & p.HasDefaultValue) return "defaults";
    }
    return o(e);
  }
  has(t) {
    return this.propertiesByName.has(t) && this.store.has(t);
  }
  keys() {
    return [...this.propertiesByName.keys()];
  }
  internalGet(t) {
    const e = this.propertiesByName.get(t);
    if (f(e)) return this.store.has(t) ? this.store.get(t) : e.metadata.value;
  }
  internalSet(t, e) {
    const i = this.propertiesByName.get(t);
    f(i) && this._internalSet(i, e);
  }
  getDependsInfo(t, e, i) {
    const r = this.propertiesByName.get(e);
    if (!f(r)) return "";
    const o = new Set(),
      a = l({
        onObservableAccessed: t => o.add(t),
        onTrackingEnd: () => {}
      }, () => r.metadata.get?.call(t));
    let n = `${i}${t.declaredClass.split(".").pop()}.${e}: ${a}\n`;
    if (0 === o.size) return n;
    i += "  ";
    for (const c of o) {
      if (!(c instanceof s)) continue;
      n += `${i}${c.propertyName}: undefined\n`;
    }
    return n;
  }
  setAtOrigin(t, e, i) {
    const s = this.propertiesByName.get(t);
    if (f(s)) return this._setAtOrigin(s, e, i);
  }
  isOverridden(t) {
    const e = this.propertiesByName.get(t);
    return void 0 !== e && !!(e.flags & p.Overridden);
  }
  clearOverride(t) {
    const e = this.propertiesByName.get(t);
    e && e.flags & p.Overridden && (e.flags &= ~p.Overridden, e.notifyChange());
  }
  override(t, e) {
    const i = this.propertiesByName.get(t);
    if (!d(i, t, e, this)) return;
    const s = i.metadata.cast;
    if (s) {
      const t = this._cast(s, e),
        {
          valid: i,
          value: r
        } = t;
      if (u.release(t), !i) return;
      e = r;
    }
    i.flags |= p.Overridden, this._internalSet(i, e);
  }
  set(t, e) {
    const i = this.propertiesByName.get(t);
    if (!d(i, t, e, this)) return;
    const s = i.metadata.cast;
    if (s) {
      const t = this._cast(s, e),
        {
          valid: i,
          value: r
        } = t;
      if (u.release(t), !i) return;
      e = r;
    }
    const r = i.metadata.set;
    r ? r.call(this.host, e) : this._internalSet(i, e);
  }
  setDefaultOrigin(t) {
    this._origin = a(t);
  }
  getDefaultOrigin() {
    return o(this._origin);
  }
  notifyChange(t) {
    const e = this.propertiesByName.get(t);
    void 0 !== e && e.notifyChange();
  }
  invalidate(t) {
    const e = this.propertiesByName.get(t);
    void 0 !== e && e.invalidate();
  }
  commit(t) {
    const e = this.propertiesByName.get(t);
    void 0 !== e && e.commit();
  }
  _internalSet(t, e) {
    const s = this.lifecycle !== i.INITIALIZING ? this._origin : r.DEFAULTS;
    this._setAtOrigin(t, e, s);
  }
  _setAtOrigin(e, i, s) {
    const r = this.store,
      o = e.propertyName;
    r.has(o, s) && t(i, r.get(o)) && ~e.flags & p.Overridden && s === r.originOf(o) || (e.invalidate(), r.set(o, i, s), e.commit(), h(this.host, e));
  }
  _cast(t, e) {
    const i = u.acquire();
    return i.valid = !0, i.value = e, t && (i.value = t.call(this.host, e, i)), i;
  }
}
class m {
  constructor() {
    this.value = null, this.valid = !0;
  }
  acquire() {
    this.valid = !0;
  }
  release() {
    this.value = null;
  }
}
const u = new e(m);
export { g as default };