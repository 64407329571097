/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
var e;
!function (e) {
  e[e.DEFAULTS = 0] = "DEFAULTS", e[e.COMPUTED = 1] = "COMPUTED", e[e.SERVICE = 2] = "SERVICE", e[e.PORTAL_ITEM = 3] = "PORTAL_ITEM", e[e.WEB_SCENE = 4] = "WEB_SCENE", e[e.WEB_MAP = 5] = "WEB_MAP", e[e.LINK_CHART = 6] = "LINK_CHART", e[e.USER = 7] = "USER";
}(e || (e = {}));
const r = e.USER + 1;
function n(r) {
  switch (r) {
    case "defaults":
      return e.DEFAULTS;
    case "service":
      return e.SERVICE;
    case "portal-item":
      return e.PORTAL_ITEM;
    case "web-scene":
      return e.WEB_SCENE;
    case "web-map":
      return e.WEB_MAP;
    case "link-chart":
      return e.LINK_CHART;
    case "user":
      return e.USER;
    default:
      return null;
  }
}
function t(r) {
  switch (r) {
    case e.DEFAULTS:
      return "defaults";
    case e.SERVICE:
      return "service";
    case e.PORTAL_ITEM:
      return "portal-item";
    case e.WEB_SCENE:
      return "web-scene";
    case e.WEB_MAP:
      return "web-map";
    case e.LINK_CHART:
      return "link-chart";
    case e.USER:
      return "user";
  }
}
function E(e) {
  return n(e);
}
function u(e) {
  return t(e);
}
function c(e) {
  return n(e);
}
function s(e) {
  return t(e);
}
export { e as OriginId, t as idToName, u as idToReadableName, s as idToWritableName, n as nameToId, r as originIdNum, E as readableNameToId, c as writableNameToId };