/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
class e {
  constructor(e) {
    this._accessed = new Set(), this._handles = [], this._observerObject = new s(e), t.register(this, new WeakRef(this._observerObject), this);
  }
  destroy() {
    t.unregister(this._observerObject), this._accessed.clear(), this._observerObject?.destroy(), this.clear();
  }
  onObservableAccessed(e) {
    this._accessed.add(e);
  }
  onTrackingEnd() {
    const e = this._handles,
      s = this._observerObject;
    this._accessed.forEach(t => {
      e.push(t.observe(s));
    }), this._accessed.clear();
  }
  clear() {
    const e = this._handles;
    for (let s = 0; s < e.length; ++s) e[s].remove();
    e.length = 0;
  }
}
class s {
  constructor(e) {
    this._notify = e, this._invalidCount = 0, this.destroyed = !1;
  }
  onInvalidated() {
    this._invalidCount++;
  }
  onCommitted() {
    if (this.destroyed) return;
    const e = this._invalidCount;
    if (1 === e) return this._invalidCount = 0, void this._notify();
    this._invalidCount = e > 0 ? e - 1 : 0;
  }
  destroy() {
    this.destroyed = !0, this._notify = i;
  }
}
const t = new FinalizationRegistry(e => {
  e.deref()?.destroy();
});
function i() {}
export { e as SimpleTrackingTarget };