/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { makeHandle as n } from "../handleUtils.js";
import { runTracked as t } from "./tracking.js";
import { SimpleTrackingTarget as r } from "./tracking/SimpleTrackingTarget.js";
let l = !1;
const u = [];
function o(u, o) {
  let e = new r(a),
    i = null,
    s = !1;
  function a() {
    if (!e || s) return;
    if (l) return void c(a);
    const n = i;
    e.clear(), l = !0, s = !0, i = t(e, u), s = !1, l = !1, o(i, n), f();
  }
  function m() {
    e && (e.destroy(), e = null, i = null);
  }
  return s = !0, i = t(e, u), s = !1, n(m);
}
function e(l, u) {
  let o = new r(i),
    e = null;
  function i() {
    u(e, f);
  }
  function c() {
    o && (o.destroy(), o = null), e = null;
  }
  function f() {
    return o ? (o.clear(), e = t(o, l), e) : null;
  }
  return f(), n(c);
}
function i(u) {
  let o = new r(i),
    e = !1;
  function i() {
    o && !e && (l ? c(i) : (o.clear(), l = !0, e = !0, t(o, u), e = !1, l = !1, f()));
  }
  function s() {
    o && (o.destroy(), o = null);
  }
  return e = !0, t(o, u), e = !1, n(s);
}
function c(n) {
  u.includes(n) || u.unshift(n);
}
function f() {
  for (; u.length;) u.pop()();
}
export { i as autorun, o as reaction, e as reactionDeferred };