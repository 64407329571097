/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { nextTick as e } from "./nextTick.js";
import t from "./PerformanceSampler.js";
import r from "./PooledArray.js";
import { createResolver as n, isAborted as s, createAbortError as o } from "./promiseUtils.js";
import { Milliseconds as a } from "./time.js";
class i {
  constructor(e) {
    this.phases = e, this.paused = !1, this.ticks = -1, this.removed = !1;
  }
}
class c {
  constructor(e) {
    this.callback = e, this.isActive = !0;
  }
  remove() {
    this.isActive = !1;
  }
}
let m = 0,
  l = 0;
const u = {
    time: a(0),
    deltaTime: a(0),
    elapsedFrameTime: a(0),
    frameDuration: a(0)
  },
  p = ["prepare", "preRender", "render", "postRender", "update", "finish"],
  f = [],
  h = new r();
class d {
  constructor(e) {
    this._task = e;
  }
  remove() {
    this._task.removed = !0;
  }
  pause() {
    this._task.paused = !0;
  }
  resume() {
    this._task.paused = !1;
  }
}
function w() {
  null != F && (cancelAnimationFrame(F), F = requestAnimationFrame(x));
}
const k = {
  frameTasks: h,
  willDispatch: !1,
  clearFrameTasks: T,
  dispatch: _,
  executeFrameTasks: D,
  reschedule: w
};
function v(t) {
  const r = new c(t);
  return f.push(r), k.willDispatch || (k.willDispatch = !0, e(_)), r;
}
function A(e) {
  const t = new i(e);
  return h.push(t), null == F && (m = performance.now(), F = requestAnimationFrame(x)), new d(t);
}
let F = null;
function T(e = !1) {
  h.forAll(e => {
    e.removed = !0;
  }), e && q();
}
function j(e) {
  l = Math.max(0, e);
}
function x() {
  const e = performance.now();
  F = null, F = h.length > 0 ? requestAnimationFrame(x) : null, k.executeFrameTasks(e);
}
function D(e) {
  const t = a(e - m);
  m = e;
  const r = l > 0 ? l : 1e3 / 60,
    n = Math.max(0, t - r);
  u.time = e, u.frameDuration = a(r - n);
  for (let s = 0; s < p.length; s++) {
    const r = performance.now(),
      n = p[s];
    h.forAll(r => {
      if (r.paused || r.removed) return;
      0 === s && r.ticks++;
      r.phases[n] && (u.elapsedFrameTime = a(performance.now() - e), u.deltaTime = 0 === r.ticks ? a(0) : t, r.phases[n]?.call(r, u));
    }), b[s].record(performance.now() - r);
  }
  q(), U.record(performance.now() - e);
}
const g = new r();
function q() {
  h.forAll(e => {
    e.removed && g.push(e);
  }), h.removeUnorderedMany(g.data, g.length), g.clear();
}
function _() {
  for (; f.length;) {
    const e = f.shift();
    e.isActive && e.callback();
  }
  k.willDispatch = !1;
}
function y(t = 1, r) {
  const a = n(),
    i = () => {
      s(r) ? a.reject(o()) : 0 === t ? a() : (--t, e(() => i()));
    };
  return i(), a.promise;
}
function M(e) {
  return y(1, e);
}
function P() {
  const e = n(),
    t = A({
      postRender: () => {
        t.remove(), v(e);
      }
    });
  return e.promise;
}
async function R() {
  await M(), await new Promise(e => requestAnimationFrame(e));
}
const b = p.map(e => new t(e)),
  U = new t("total");
export { d as FrameTaskHandle, A as addFrameTask, k as debug, b as performanceInfo, U as performanceTotal, v as schedule, j as setFrameDuration, R as waitAnimationFrame, P as waitRender, M as waitTick, y as waitTicks };